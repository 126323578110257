<template>
  <div>
    <h1 class="count">
      {{ chargeFee }}
      <span class="unit">{{ $tc('common.unit', chargeFee) }}</span>
    </h1>
    <div id="paypal-button-container"></div>
  </div>
</template>

<script>
import { unitFormat } from '@/utils'
export default {
  data() {
    const { chargeFee } = this.$route.query
    return {
      resultTime: 5,
      isShow: true,
      chargeFee: unitFormat(+chargeFee),
      capturedChargeOrderId: '',
      capturedUserId: '',
      capturedServerSign: ''
    }
  },
  mounted() {
    this.setRootFontSize()
    this.loadPaypalJs()
  },
  methods: {
    setRootFontSize() {
      const doc = document.documentElement
      const deviceWidth = Math.min(document.body.clientWidth || doc.clientWidth)
      if (deviceWidth >= 1080) {
        doc.style.fontSize = '100px'
      } else {
        doc.style.fontSize = (deviceWidth / 1080) * 100 + 'px'
      }
    },
    loadPaypalJs() {
      let clientId = window.location.host.includes('staging')
        ? 'AdaKlk7B9THRpnZLSftO_UE5C19eUq0JyUzTrd1pL1iYSW-cxcUCjVyZzYmviwyLoUycOKYt8lqeDN1i'
        : 'AfYJ5fkU55AYs4zhdk4L11g4ekoYhDb7Anp3E9SeDxHp-tru8_q72-ks5v0KCNO0kGRj1iqsRHO8J-gj'
      let that = this
      const ppJsSrc = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=HKD`
      const paypalScript = document.createElement('script')
      paypalScript.type = 'text/javascript'
      paypalScript.src = ppJsSrc
      let first = document.body.firstChild
      document.body.insertBefore(paypalScript, first)
      paypalScript.addEventListener('load', function() {
        if (window.paypal && window.paypal.Buttons) {
          paypal
            .Buttons({
              style: { color: 'blue', shape: 'pill' },
              createOrder: () =>
                Promise.resolve(that.$route.query.paypalCreateOrderId),
              onApprove: () => {
                that.paypalCapture()
              },
              onError: function() {
                window.$$toast(that.$t('paypal.tips'))
              }
            })
            .render('#paypal-button-container')
        } else {
          window.$$toast(that.$t('error.title'))
        }
      })
    },

    showTips() {
      this.$messageBox({
        content: this.$t('paypal.warn'),
        confirm: this.$t('paypal.completed'),
        type: 'one',
        modalClose: false
      }).then(() => {
        this.payResult()
      })
    },

    paypalCapture() {
      this.$http
        .paypalCapture(this.$route.query)
        .then(res => {
          if (res.errCode === 200) {
            this.capturedChargeOrderId = res.chargeOrderId
            this.capturedUserId = res.userId
            this.capturedServerSign = res.serverSign
            this.payResult()
          } else {
            window.$$toast(res.errDesc + ' ' + this.$t('paypal.retry'))
          }
        })
        .catch(() => {
          this.$router.push({
            name: 'error',
            params: { from: 'paypal' },
            replace: true
          })
        })
    },

    payResult() {
      let userId = this.capturedUserId,
        chargeOrderId = this.capturedChargeOrderId,
        serverSign = this.capturedServerSign
      this.$http
        .payResultV2({
          userId,
          chargeOrderId,
          serverSign
        })
        .then(res => {
          if (res.returnUrl) {
            window.location.replace(res.returnUrl)
          } else if (--this.resultTime >= 0) {
            window.$$toast(this.$t('paypal.wait'))
            setTimeout(() => {
              this.payResult()
            }, 3000)
          } else if (this.isShow) {
            this.isShow = false
            this.resultTime = 4
            this.showTips()
          } else {
            return new Promise((resolve, reject) => {
              reject('error')
            })
          }
        })
        .catch(() => {
          this.$router.push({
            name: 'error',
            params: { from: 'paypal' },
            replace: true
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#paypal-button-container {
  padding: 36px;
}
.count {
  margin-bottom: 40px;
  margin-top: 60px;
  font-weight: bold;
  font-size: 90px;
  text-align: center;
  font-family: Mitype;
  .unit {
    font-size: 48px;
    font-weight: normal;
  }
}
</style>
